<template>
  <div class="video" @mouseenter="idleState = false" @mouseleave="idleState = true">
    <video id="video-player" ref="videoPlayer" class="video-js" :class="{'mobile': isMobile}"></video>
    <lesson-video-popup v-if="showNextLesson && !guest" ref="videoPopup" />
    <annotations :idle="idleState" v-if="!guest" />
  </div>
</template>

<script>
import '@mycujoo/videojs-hls-quality-selector'
import 'videojs-markers'
import 'videojs-mobile-ui'
import LessonVideoPopup from './LessonVideoPopup.vue'
import Annotations from './annotations/index.vue'
import videojs from 'video.js'
import fscreen from '@shared/base/fscreen'
import NProgress from 'nprogress';
import { mapState } from 'vuex'

// Language support
import es from 'video.js/dist/lang/es.json';
import de from 'video.js/dist/lang/de.json';
import fr from 'video.js/dist/lang/fr.json';
import pt from 'video.js/dist/lang/pt-PT.json';

export default {
  components: {
    LessonVideoPopup,
    Annotations
  },
  data() {
    return {
      player: null,
      markers: null,
      minProgress: 10,
      progressUpdatedAt: null,
      idleState: true,
      idleTimer: null,
      languages: {
        'es': 'Spanish',
        'de': 'German',
        'fr': 'French',
        'pt': 'Portuguese',
      },
      translations: {
        'es': es,
        'de': de,
        'fr': fr,
        'pt': pt,
      }
    }
  },
  computed: {
    ...mapState('lesson', {
      lesson: state =>  state.lesson,
      module: state => state.module,
      annotationActive: state => state.annotationActive,
      showUpcoming: state => state.showUpcoming,
      ignoreProgress: state => state.ignoreProgress,
      previousTimestamp: state => state.previousTimestamp,
      lockedContentOrigin: state => state.lockedContentOrigin
    }),
    ...mapState('course', {
      course: state => state.course,
    }),
    ...mapState('user', {
      guest: state => state.guest
    }),
    options () {
      return {
        preload: 'metadata',
        language: this.currentLanguage,
        autoplay: true,
        playsinline: true,
        aspectRatio: '16:9',
        controls: true,
        responsive: true,
        fluid: true,
        videojsPlayPauseButton: true,
        nativeControlsForTouch: false,
        enableSmoothSeeking: true,
        playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
        html5: {
          nativeTextTracks: true
        },
        controlBar: {
          children: [
            ...!this.isPhone ? ['playToggle'] : [],
            "currentTimeDisplay",
            "progressControl",
            ...!this.isPhone ? ['nextVideo'] : [],
            "subsCapsButton",
            "audioTrackButton",
            ...!this.isMobile ? ['volumePanel'] : [],
            "concreteButton",
            "playbackRateMenuButton",
            ...!this.isPhone ? ['pictureInPictureToggle'] : [],
            ...!this.isPhone ? ['fullscreenToggle'] : [],
          ],
          ...!this.isMobile && {
            volumePanel: {
              inline: false
            }
          },
        },
        sources: [
          {
            src: `https://fast.wistia.com/embed/medias/${this.lesson.video_id}.m3u8`
          }
        ]
      }
    },
    trackProgress() {
      return this.lesson && this.lesson.is_video && !this.lesson.is_watched
    },
    isPreviousLesson() {
      return parseInt(this.$route.params.id) === this.lockedContentOrigin
    },
    isPreviousTimestamp() {
      return this.lesson.is_watched && Number.isInteger(this.previousTimestamp)
    },
    timestamp() {
      return this.isPreviousLesson && this.isPreviousTimestamp ? this.previousTimestamp : this.$route.params.timestamp
    },
    startTime() {
      if (this.ignoreProgress) {
        return 0
      }
      return this.timestamp || this.timestamp === "0" ? this.timestamp : this.lesson.is_watched ? 0 : this.lesson.progress - this.minProgress
    },
    nextProgressUpdateAt() {
      return (this.progressUpdatedAt ?? this.startTime) + this.minProgress
    },
    showNextLesson () {
      return this.showUpcoming && this.lesson.next_lesson_id
    },
  },
  methods: {
    mountPlayer () {
      this.player = videojs(this.$refs.videoPlayer, this.options, () => {
        this.player.addClass('vjs-sublime-skin')
        this.player.mobileUi(
          {
            fullscreen: this.isPhone && {
              enterOnRotate: true,
              exitOnRotate: true
            }
          }
        )
        this.mobileUI()
        this.handleLoaded()
        this.handleMarkers()
        this.handleTimeUpdate()
        this.handleEnded()
        this.handlePlayButton()

        if (this.isMobile) return

        this.player.hlsQualitySelector()
      })
    },
    getMarkers () {
      const options = {method: 'GET', headers: {accept: 'application/json'}}

      fetch(`https://fast.wistia.com/embed/medias/${this.lesson.video_id}.json`, options)
        .then(response => response.json())
        .then(response => {
          this.markers = response.media.embedOptions.plugin.chapters?.chapterList.reduce((acc, chapter) => {
            if (chapter.deleted === 'true') return acc;
            return [
              ...acc,
              {
                time: parseInt(chapter.time),
                text: chapter.title.replace(/&amp;/g, '&')
              }
            ];
          }, []);

          this.mountPlayer()
        })
        .catch(err => console.error(err))
    },
    handleLanguage () {
      videojs.addLanguage(this.currentLanguage, this.translations[this.currentLanguage])
    },
    handleLoaded ()  {
      const queryTimestamp = this.$route.query?.t

      this.player.on('loadeddata', () => {
        this.setDefaultLanguage()
        this.handleEvents()
        this.player.currentTime((queryTimestamp ? Math.min(this.lesson.duration - this.minProgress, queryTimestamp) : 0) || this.startTime)
        NProgress.done()
      })
    },
    handleMarkers () {
      if (!this.markers) return

      this.player.markers({
        markers: this.markers,
        markerTip:{
          display: true,
          text: marker => marker.text
        },
        markerStyle: {
          'width': '8px',
          'background-color': '#a6a5a6',
          'border-radius': '50%',
        },
      })
    },
    handleTimeUpdate () {
      let nextThreshold = Math.trunc(this.player.currentTime()) + this.minProgress

      this.player.on('timeupdate', () => {
        if (this.player.paused()) return

        const seconds = Math.trunc(this.player.currentTime())

        if (seconds > nextThreshold) {
          nextThreshold += this.minProgress
          this.handleProgress(seconds)
        }
        if (seconds > this.minProgress) {
          this.handleAnnotations(seconds)
        }
      })
    },
    handleProgress (seconds) {
      if (!this.trackProgress) return
      if (seconds < this.nextProgressUpdateAt) return

      this.progressRequestInProgress = true

      this.$store.dispatch('lesson/setProgress', {
        lessonProgress: seconds
      }).finally(() => {
        this.progressRequestInProgress = false
        this.progressUpdatedAt = seconds
      })
    },
    handleAnnotations(seconds) {
      if (!this.lesson || !this.lesson.annotations) {
        return
      }

      let isShowingLink = false

      this.lesson.annotations.forEach(annotation => {
        if (annotation.type === 'related_video') return

        if (seconds >= annotation.start && seconds < annotation.end) {
          if (!isShowingLink) {
            this.$store.dispatch('lesson/toggleAnnotation', annotation.id)
            isShowingLink = true
          }
        } else {
          if (annotation.id === this.annotationActive) {
            this.$store.dispatch('lesson/toggleAnnotation', null)
            isShowingLink = false
          }
        }
      })
    },
    handleEnded () {
      this.player.on('ended', () => {
        const playerNest = document.getElementsByClassName('video-js')[0]

        // Open "next lesson" popup
        if (this.lesson.next_lesson_id) {
          this.$store.dispatch('lesson/setShowUpcoming', true)
          this.$nextTick(() => {
            playerNest.append(this.$refs.videoPopup.$el)
          })
        }

        // Close fullscreen mode on mobile devices
        if (!fscreen.fullscreenEnabled) {
          document.querySelector('video').webkitExitFullscreen()
        }
      })
    },
    handlePlayButton () {
      this.player.on('play', () => {
        this.fireTrackerEvent('button_clicked', { button: 'video_player_view_play_pause' })

        if (!this.isMobile) return

        this.player.removeChild('BigPlayButton')
      })

      this.player.on('pause', () => {
        this.fireTrackerEvent('button_clicked', { button: 'video_player_view_play_pause' })
      })
    },
    handleIdle (time) {
      this.idleState = false

      clearTimeout(this.idleTimer)

      this.idleTimer = setTimeout(() => { this.idleState = true }, time)
    },
    handleEvents () {
        this.player.controlBar.getChild('subsCapsButton').on('click', () => {
          const menuVisible = this.player.controlBar.getChild('subsCapsButton').menu.el_.classList.contains('vjs-lock-showing')
          this.fireTrackerEvent('button_clicked', { button: 'video_player_settings_captions_section', is_on: menuVisible })
        })
        this.player.controlBar.getChild('qualityButton')?.on('click', () => {
          this.fireTrackerEvent('button_clicked', { button: 'video_player_view_playback_quality' })
        })
        this.player.controlBar.getChild('playbackRateMenuButton').on('click', () => {
          this.fireTrackerEvent('button_clicked', { button: 'video_player_view_playback_speed' } )
        })
        this.player.on('ratechange', () => {
          this.fireTrackerEvent('video_settings_playback_speed_changed', null, { updated_playback_speed: this.player.playbackRate() })
        })
        this.player.on('seeked', () => {
          if (!this.player.currentTime() > 0) return
          this.fireTrackerEvent('video_player_view_drag_seek_bar')
        })
        this.player.on('fullscreenchange', () => {
          this.fireTrackerEvent('button_clicked', { button: 'video_player_view_fullscreen', is_on: this.player.isFullscreen() })
        })
        this.player.on('enterpictureinpicture', () => {
          this.fireTrackerEvent('button_clicked', { button: 'video_player_view_picture_in_picture', is_on: true })
        })
        this.player.on('leavepictureinpicture', () => {
          this.fireTrackerEvent('button_clicked', { button: 'video_player_view_picture_in_picture', is_on: false })
        })
    },
    setDefaultLanguage () {
      const tracks = document.querySelectorAll('.vjs-audio-button .vjs-menu-content .vjs-menu-item-text')

      tracks.forEach(track => {
        if (track.innerHTML.includes(this.languages[this.currentLanguage])) {
          track.click()
        }
      })

      if (this.isMobile) return

      const quality = document.querySelector('.vjs-quality-selector .vjs-menu-title:first-child')

      quality.innerHTML = this.$t('wistia.quality')
    },
    createNextVideoButton () {
      const Component = videojs.getComponent('Component');
      const vm = this

      class NextVideo extends Component {
        constructor(player, options = {}) {
          super(player, options);
        }

        createEl () {
          return videojs.dom.createEl('div', {
            className: 'vjs-next-video vjs-control vjs-button',
            title: vm.$t('wistia.goToNextVideo'),
            innerHTML: `
              <span class="vjs-icon-placeholder" aria-hidden="true"><img src="${vm.vaporAsset('images/icons/next-video.svg')}" alt="next" /></span>
              <span class="vjs-control-text" aria-live="polite">${vm.$t('wistia.goToNextVideo')}</span>
            `,
            onclick: () => {
              vm.fireTrackerEvent('button_clicked', 'video_player_view_play_next')
              vm.$router.push({ name: 'lesson', params: { id: vm.lesson.next_lesson_id } })
            }
          });
        }
      }

      videojs.registerComponent('NextVideo', NextVideo);
    },
    mobileUI () {
      if (!this.isPhone) return

      this.player.getChild('TitleBar').addChild('NextVideo');
      this.player.getChild('TitleBar').addChild('fullscreenToggle');
      this.player.titleBar.show()
    },
    fireTrackerEvent(action, name, customData) {
      if (customData) {
        this.trackEvent(action, customData)
        return
      }

      if (!this.course || !this.module || !this.lesson) return

      this.trackEvent(action, {
        ...(name && { button: name }),
        course_id: this.course.id,
        course_slug: this.course.product_slug,
        course_name: this.course.title,
        module_id: this.module.id,
        module_name: this.module.title,
        lesson_id: this.lesson.id,
        lesson_name: this.lesson.title,
      })
    }
  },
  mounted() {
    this.createNextVideoButton()
    this.handleLanguage()
    this.getMarkers()
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>

<style lang="scss" scoped>
.video {
  position: relative;
  aspect-ratio: 16/9;

  .video-js {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @include tablet-landscape {
      border-radius: 8px;
    }
    @include ipad-pro-129(portrait) {
      border-radius: 0;
    }

    :deep(.vjs-menu-item-text) {
      text-transform: capitalize;
    }
  }
}
</style>