<template>
  <div class="home">
    <app-container>
      <div class="home__courses-nest">
        <div class="home__courses" v-if="courses">
          <home-card
            v-for="card in courses"
            :card="card"
            :key="card.id"
          ></home-card>
        </div>
        <courses-placeholder v-else></courses-placeholder>
      </div>
    </app-container>
  </div>
</template>

<script>
import CoursesPlaceholder from "./HomeCoursesPlaceholder.vue";
import AppContainer from "@course-portal/components/layout/AppContainer.vue";
import HomeCard from "./HomeCard.vue";
import pwaNotifications from "@course-portal/mixins/pwaNotifications";
import { mapState } from "vuex";
import { iosview } from "@course-portal/iosview.js";
import NProgress from 'nprogress';

export default {
  name: "HomeIndex",
  components: {
    CoursesPlaceholder,
    AppContainer,
    HomeCard,
  },
  mixins: [pwaNotifications],
  computed: {
    ...mapState("courses", {
      courses: (state) => state.courses,
      reloadCourses: (state) => state.reloadCourses,
    }),
    ...mapState("user", {
      user: (state) => state.user,
    }),
    isFirstLogin() {
      return this.$route.query.welcome === "true";
    },
    /**
     * To be updated once backend is ready
     */
    isPurchase() {
      return this.$route.query.purchase === "true";
    },
    isHideWelcome() {
      return sessionStorage.getItem("hideWelcome");
    }
  },
  watch: {
    deviceToken: {
      immediate: true,
      handler (val) {
        if (!val || !this.isAppMode) return
        this._getAppSetup()
      }
    },
    _state (val) {
      this.handlePermissionsState(val)
    }
  },
  methods: {
    throwError() {
      throw new Error('Sentry Error');
    },
    setHideWelcome() {
      sessionStorage.setItem("hideWelcome", "true");
    },
    handlePermissionsState (state) {
      const allowed = ['authorized', 'ephemeral', 'provisional'].includes(state);

      if (state === "notDetermined") {
        this.$router.push({ name: "notifications", params: { step: "1" } });
      } else if (allowed) {
        this._pushDeviceToken()
      } else if (state === "denied") {
        this._removeDeviceToken()
      }
    },
  },
  mounted() {
    if (this.reloadCourses) {
      Promise.all([
        this.$store.dispatch("courses/resetCourses"),
        this.$store.dispatch("courses/setCourses"),
        this.$store.dispatch("courses/reloadCourses", false)
      ]).finally(() => {
        NProgress.done();
      })
    } else {
      NProgress.done();
    }

    const notificationLink = localStorage.getItem('notificationLink');
    
    if (notificationLink && notificationLink.trim() !== '') {
      console.log("notificationLink", notificationLink);
      
      const event = new CustomEvent('handle-local-storage-redirect', {
        detail: { link: notificationLink }
      });
      window.dispatchEvent(event);

      localStorage.removeItem('notificationLink');
    }
  },
  unmounted() {
    this.setHideWelcome();
  },
};
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  padding: 30px 0 45px 0;

  @include tablet-portrait {
    padding: 45px 0 70px 0;
  }

  &__courses-nest {
    position: relative;
  }

  &__courses {
    position: relative;

    @include phone-landscape {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;
      row-gap: 24px;
    }
    @include tablet-landscape {
      column-gap: 32px;
      row-gap: 32px;
    }
  }
}
</style>
