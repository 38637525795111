<template>
  <div class="profile">
    <app-container :medium="true">
      <div class="profile__user" v-if="user && currentBadge">
        <router-link :to="{name: 'profile-edit'}" class="profile__user-edit" @click="trackProfileEdit">
          <font-awesome-icon :icon="['fas', 'pencil']" class="profile__user-edit-icon" />
          <p v-html="$t('profile.editProfileSettings')"></p>
        </router-link>

        <app-avatar class="profile__avatar" :src="avatarImage">
          <div class="profile__avatar-icon" @click="$refs.file.click()">
            <font-awesome-icon :icon="['fas', 'camera']" />
          </div>

          <input type="file" ref="file" accept=".jpg,.jpeg,.png,.bmp,.gif,.svg,.webp" class="profile__avatar-input" @change="uploadAvatar">
        </app-avatar>

        <div class="profile__user-name">{{ `${user.first_name} ${user.last_name}` }}</div>

        <div class="profile__badge" :class="`profile__badge--${currentBadge.name}`" v-if="currentBadge">{{ $t(`achievements.${currentBadge.name}`) }}</div>
      </div>

      <profile-placeholder v-else></profile-placeholder>

      <div class="profile__nav-holder">
        <app-tabs :tabs="tabs" :activeTab="activeTab" :module="'profile'"></app-tabs>
      </div>

      <div class="profile__tabs-holder">
        <keep-alive>
          <component :is="tabs[activeTab].component" :tabId="tabs[activeTab].id"></component>
        </keep-alive>
      </div>
    </app-container>
  </div>
</template>

<script>
import ProfilePlaceholder from './ProfilePlaceholder.vue'
import ProfileAchievements from './ProfileAchievements.vue'
import ProfileActivity from './ProfileActivity.vue'
import AppContainer from '@course-portal/components/layout/AppContainer.vue'
import { mapGetters, mapState } from 'vuex'
import NProgress from 'nprogress';

export default {
  name: 'ProfileIndex',
  components: {
    ProfilePlaceholder,
    ProfileAchievements,
    ProfileActivity,
    AppContainer
  },
  computed: {
    ...mapGetters('user', ['avatarImage']),
    ...mapState('user', {
      user: state => state.user
    }),
    ...mapState('profile', {
      currentBadge: state => state.currentBadge,
      activity: state => state.activity,
      achievements: state => state.achievements,
      tabs: state => state.tabs,
      activeTab: state => state.activeTab
    }),
    ...mapState('notifications', {
      newNotifications: state => state.newNotifications
    })
  },
  methods: {
    uploadAvatar (event, files) {
      let file

      files ? file = files[0] : file = event.target.files[0]

      if (!file) {
        return false
      }
      if (!file.type.match('image.*')) {
        return false
      }

      const blob = URL.createObjectURL(file)

      const reader = new FileReader()

      reader.onload = () => {
        this.$store.dispatch('user/cropAvatar', blob)
        this.$store.dispatch('user/setAvatarInput', this.$refs.file)
        this.$store.dispatch('layout/toggleCropping', true)
      }
      reader.readAsDataURL(file)

      this.trackEvent('button_clicked', { button: 'edit_profile_view_upload_profile_picture' })
    },
    trackProfileEdit () {
      this.trackEvent('button_clicked', { button: 'profile_view_open_settings_edit_profile' })
    }
  },
  mounted () {
    Promise.all([
      this.$store.dispatch('profile/getAchievements'),
      this.$store.dispatch('profile/selectTab', this.newNotifications ? 1 : 0),
      this.$store.dispatch('layout/setHeaderTitle', this.$t('profile.profile'))
    ]).finally(() => {
      NProgress.done();
    });

  },
  beforeUnmount () {
    this.$store.dispatch('profile/readAllActivities')
    this.$store.dispatch('layout/setHeaderTitle', null)
  }
}
</script>

<style lang="scss" scoped>
  .profile {
    padding: 20px 0 50px 0;

    @include tablet-landscape {
      padding: 20px 0 40px 0;
    }
    @include tablet-portrait {
      padding: 60px 0;
    }
    @include iphone-11 (landscape) {
      padding: 30px 0;
    }
    @include iphone-11-pro (landscape) {
      padding: 30px 0;
    }
    @include iphone-11-pro-max (landscape) {
      padding: 30px 0;
    }
    @include iphone-12 (landscape) {
      padding: 30px 0;
    }
    @include iphone-12-pro-max (landscape) {
      padding: 30px 0;
    }

    &__user {
      display: flex;
      flex-flow: column;
      align-items: center;
      position: relative;
    }

    &__user-name {
      font-size: $header-md;
      font-weight: $semiBold;
    }

    &__badge {
      line-height: 28px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      padding: 0 12px;
      margin-top: 7px;
      border-radius: 4px;
      background-color: var(--beginner);

      &--Intermediate {
        background-color: var(--intermediate);
      }
      &--Advanced {
        background-color: var(--advanced);
      }
      &--Master {
        background-color: var(--master);
      }
      &--Staff {
        background-color: var(--staff);
      }
    }

    &__user-edit {
      display: flex;
      align-items: center;
      align-self: flex-end;
      position: relative;
      font-size: 16px;
      line-height: 28px;
      margin: 0 0 20px 0;

      @include tablet-portrait {
        position: absolute;
        top: 0;
        right: 0;
        line-height: 140%;
        margin: 0;
      }

      &:hover {
        text-decoration: underline;
      }

      p {
        margin: 0
      }

      ::v-deep(span) {
        display: none;

        @include phone-landscape {
          display: inline;
        }
      }
    }

    &__user-edit-icon {
      font-size: 14px;
      margin-right: 8px;
    }

    &__avatar {
      height: 100px;
      width: 100px;
      margin-bottom: 13px;
      background-color: var(--gray-6);

      @include tablet-portrait {
        height: 142px;
        width: 142px;
      }
      @include iphone-11 (landscape) {
        height: 100px;
        width: 100px;
      }
      @include iphone-11-pro (landscape) {
        height: 100px;
        width: 100px;
      }
      @include iphone-11-pro-max (landscape) {
        height: 100px;
        width: 100px;
      }
      @include iphone-12 (landscape) {
        height: 100px;
        width: 100px;
      }
      @include iphone-12-pro-max (landscape) {
        height: 100px;
        width: 100px;
      }
    }

    &__avatar-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      right: -3px;
      height: 32px;
      width: 32px;
      color: var(--black);
      border-radius: 50%;
      background: var(--white);
      z-index: 1;
      cursor: pointer;

      @include tablet-portrait {
        bottom: 5px;
        right: 5px;
      }
    }

    &__avatar-input {
      display: none;
    }

    &__nav-holder {
      display: flex;
      flex-flow: column;
      align-items: center;
      position: relative;
      margin: 20px -10px 0 -10px;

      @include tablet-portrait {
        margin: 40px 0 0 0;
      }
      @include iphone-11 (landscape) {
        margin: 20px -10px 0 -10px;
      }
      @include iphone-11-pro (landscape) {
        margin: 20px -10px 0 -10px;
      }
      @include iphone-11-pro-max (landscape) {
        margin: 20px -10px 0 -10px;
      }
      @include iphone-12 (landscape) {
        margin: 20px -10px 0 -10px;
      }
      @include iphone-12-pro-max (landscape) {
        margin: 20px -10px 0 -10px;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background: var(--gray-5);
      }

      :deep(.tabs__item) {
        min-width: 146px;
        text-align: center;
        padding: 16px;
      }
    }

    &__tabs-holder {
      padding: 24px 0 0 0;

      @include tablet-portrait {
        padding: 32px 0 0 0;
      }
      @include iphone-11 (landscape) {
        padding: 24px 0 0 0;
      }
      @include iphone-11-pro (landscape) {
        padding: 24px 0 0 0;
      }
      @include iphone-11-pro-max (landscape) {
        padding: 24px 0 0 0;
      }
      @include iphone-12 (landscape) {
        padding: 24px 0 0 0;
      }
      @include iphone-12-pro-max (landscape) {
        padding: 24px 0 0 0;
      }
    }
  }
</style>
