<template>
    <div class="notification-banner">
        <div class="notification-banner__title">
            <h3>{{ $t('camguru.GetPersonalizedNotifications') }}</h3>
        </div>
        <p class="notification-banner__paragraph" v-html="$t('camguru.StayUpdatedParagraph')"> </p>
        <div>
            <button class="button button--white" @click="turnNotificationsOn">{{ $t('camguru.TurnOnNotifications') }}</button>
        </div>
    </div>
</template>

<script>
import pwaNotifications from '@course-portal/mixins/pwaNotifications'

export default {
    name: 'notifications-banner',
    mixins: [pwaNotifications],
    methods: {
        turnNotificationsOn () {
            const notAllowed = ['denied', 'unknown'].includes(this._state);
            
            if (!notAllowed) {
                this.trackEvent('button_clicked', { 
                    button: 'activity_allow_notifications_banner', 
                    state: 'allowed_on_ios' 
                });
                this.$store.dispatch('pwa/setAppNotificationsAllowed', true)
                this.$store.dispatch("settings/updateSettings", {
                    key: 'cam_guru_engagement_reminder_notification',
                    choice: true,
                })
                this._pushDeviceToken()
            } else if (notAllowed) {
                this.trackEvent('button_clicked', { 
                    button: 'activity_allow_notifications_banner', 
                    state: 'not_allowed_on_ios' 
                });
                this._iosview.initiateAllowNotificationsPopup();
            }
        }
    },
    mounted () {
        this._checkPermissionState()
    }
}
</script>

<style lang="scss" scoped>
.notification-banner {
    border-radius: 8px;
    border: 1px solid #3A3A3C;
    background: #0E0E0F;
    padding: 16px 20px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    
    &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        font-size: 17px;

        & > svg {
            font-size: 20px;
        } 

        & > h3 {
            margin: 0;
        }
    }

    & > div > button {
        display: flex;
        padding: 14px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 6px;
        background: #FFF;
        font-weight: 600;
        margin-top: 6px;
        font-size: 16px;
        color: black;
    }

    &__paragraph {
        margin: 0;
        font-size: 15px;
    }
}
   
</style>