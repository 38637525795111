<template>
  <div class="profile-edit">
    <app-container :small="true">
      <router-link
        :to="{ name: 'profile' }"
        tag="h2"
        class="profile-edit__close-mobile"
      >
        <font-awesome-icon
          :icon="['fas', 'chevron-left']"
          class="profile-edit__close-mobile-icon"
        />
        {{ $t("profile.profile") }}
      </router-link>

      <h2 class="profile-edit__block-title profile-edit__block-title--main">
        {{ $t("profile.profileAndSettings") }}
        <router-link :to="{ name: 'profile' }" class="profile-edit__close"
          >&times;</router-link
        >
      </h2>

      <template v-if="user && settings">
        <div class="profile-edit__block">
          <profile-edit-avatar></profile-edit-avatar>

          <form class="profile-edit__fields" autocomplete="off">
            <div class="profile-edit__field profile-edit__field--half">
              <div class="profile-edit__field-nest">
                <input
                  type="text"
                  class="profile-edit__input profile-edit__input--required"
                  id="first_name"
                  placeholder=" "
                  v-model="user.first_name"
                  @input="delete userErrors.firstName"
                  data-lpignore="true"
                  required
                />
                <label
                  for="first_name"
                  class="profile-edit__placeholder"
                  :class="{
                    'profile-edit__placeholder--error': userErrors.firstName,
                  }"
                >
                  {{
                    userErrors.firstName
                      ? userErrors.firstName
                      : $t("forms.firstName")
                  }}
                </label>
              </div>
            </div>

            <div class="profile-edit__field profile-edit__field--half">
              <div class="profile-edit__field-nest">
                <input
                  type="text"
                  class="profile-edit__input"
                  id="last_name"
                  placeholder=" "
                  v-model="user.last_name"
                  data-lpignore="true"
                />
                <label for="last_name" class="profile-edit__placeholder">{{
                  $t("forms.lastName")
                }}</label>
              </div>
            </div>

            <div class="profile-edit__field">
              <div class="profile-edit__field-nest">
                <input
                  type="text"
                  class="profile-edit__input profile-edit__input--required"
                  placeholder=" "
                  v-model="user.email"
                  data-lpignore="true"
                  required
                  disabled
                />
                <span
                  class="profile-edit__placeholder"
                  :class="{ 'profile-edit__placeholder--error': !user.email }"
                >
                  {{
                    user.email
                      ? $t("forms.emailAddress")
                      : $t("forms.pleaseEnterYourEmailAddress")
                  }}
                </span>
              </div>
              <p class="profile-edit__field-note">
                {{ $t("profile.changeEmail") }}
                <a
                  class="profile-edit__field-note-link show-menu"
                  show-menu="true"
                  @click="openContactForm"
                  v-html="$t('profile.contactUs')"
                  ></a
                >.
              </p>
            </div>
          </form>

          <app-button
            class="profile-edit__button"
            :class="{ 'profile-edit__button--saved': profileSaved }"
            color="white"
            @click="saveProfile"
          >
            <span
              class="profile-edit__button-saving"
              v-if="profileSaving"
            ></span>
            <template v-else>
              {{
                profileSaved ? $t("profile.saved") : $t("profile.saveProfile")
              }}
            </template>
          </app-button>
        </div>

        <profile-edit-app-notifications @updateSettings="updateAppSettings" v-if="isAppMode && settings" />

        <div class="profile-edit__block">
          <h2 class="profile-edit__block-title">
            {{ $t("profile.emailNotifications") }}
          </h2>

          <div class="profile-edit__fields" v-if="settings">
            <div class="profile-edit__field">
              <div class="profile-edit__field-nest">
                <label class="profile-edit__field-label">{{
                  $t("profile.someoneReplies")
                }}</label>
                <div
                  class="profile-edit__toggler-nest"
                  :class="{
                    'profile-edit__toggler-nest--saving': isUpdatingField(
                      'email_comment_replies'
                    ),
                  }"
                >
                  <transition name="fade">
                    <span
                      class="profile-edit__toggler-saved"
                      v-if="
                        isUpdatedField('email_comment_replies') &&
                        !isUpdatingField('email_comment_replies')
                      "
                    >
                      <font-awesome-icon :icon="['fas', 'check']" />
                    </span>
                  </transition>
                  <transition name="fade">
                    <span
                      class="profile-edit__toggler-saving"
                      v-if="isUpdatingField('email_comment_replies')"
                    >
                      <font-awesome-icon :icon="['fas', 'spinner']" spin />
                    </span>
                  </transition>
                  <app-toggler
                    v-model="settings.email_comment_replies"
                    @change="updateSettings($event, 'email_comment_replies')"
                  ></app-toggler>
                </div>
              </div>
            </div>

            <div class="profile-edit__field">
              <div class="profile-edit__field-nest">
                <label class="profile-edit__field-label">{{
                  $t("profile.courseProgress")
                }}</label>
                <div
                  class="profile-edit__toggler-nest"
                  :class="{
                    'profile-edit__toggler-nest--saving': isUpdatingField(
                      'email_course_reminders'
                    ),
                  }"
                >
                  <transition name="fade">
                    <span
                      class="profile-edit__toggler-saved"
                      v-if="
                        isUpdatedField('email_course_reminders') &&
                        !isUpdatingField('email_course_reminders')
                      "
                    >
                      <font-awesome-icon :icon="['fas', 'check']" />
                    </span>
                  </transition>
                  <transition name="fade">
                    <span
                      class="profile-edit__toggler-saving"
                      v-if="isUpdatingField('email_course_reminders')"
                    >
                      <font-awesome-icon :icon="['fas', 'spinner']" spin />
                    </span>
                  </transition>
                  <app-toggler
                    v-model="settings.email_course_reminders"
                    @change="updateSettings($event, 'email_course_reminders')"
                  ></app-toggler>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="profile-edit__block">
          <h2 class="profile-edit__block-title">
            {{ $t("profile.settings") }}
          </h2>

          <div class="profile-edit__fields">
            <div class="profile-edit__field">
              <div class="profile-edit__field-nest">
                <label class="profile-edit__field-label">{{
                  $t("profile.autoplayNextLesson")
                }}</label>
                <div
                  class="profile-edit__toggler-nest"
                  :class="{
                    'profile-edit__toggler-nest--saving':
                      isUpdatingField('autoplay'),
                  }"
                >
                  <transition name="fade">
                    <span
                      class="profile-edit__toggler-saved"
                      v-if="
                        isUpdatedField('autoplay') &&
                        !isUpdatingField('autoplay')
                      "
                    >
                      <font-awesome-icon :icon="['fas', 'check']" />
                    </span>
                  </transition>
                  <transition name="fade">
                    <span
                      class="profile-edit__toggler-saving"
                      v-if="isUpdatingField('autoplay')"
                    >
                      <font-awesome-icon :icon="['fas', 'spinner']" spin />
                    </span>
                  </transition>
                  <app-toggler
                    v-model="settings.autoplay"
                    @change="updateSettings($event, 'autoplay')"
                  ></app-toggler>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="profile-edit__block" v-if="isAppMode">
          <div class="profile-edit__field" @click="handleRestorePurchases">
            <div class="profile-edit__field-nest">
              <label class="profile-edit__field-label">{{ $t('profile.restorePurchases') }}</label>
            </div>
          </div>
        </div>

        <div class="profile-edit__footer" v-if="isAppMode">
          <div class="profile-edit__logo">
            <img src="https://images.iphonephotographyschool.com/36321/Frame.svg" alt="logo">
            CamGuru
          </div>
          <div class="profile-edit__delete-account" @click="toggleDeleteAccount">{{ $t('deleteAccount.deleteYourAccount') }}</div>
        </div>
      </template>
      <profile-edit-placeholder v-else></profile-edit-placeholder>
    </app-container>

    <transition name="slide">
      <profile-delete-account v-if="deleteAccount" />
    </transition>
  </div>
</template>

<script>
import AppContainer from "@course-portal/components/layout/AppContainer.vue";
import ProfileEditPlaceholder from "./ProfileEditPlaceholder.vue";
import ProfileEditAvatar from "./ProfileEditAvatar.vue";
import ProfileEditAppNotifications from "./ProfileEditAppNotifications.vue";
import ProfileDeleteAccount from "./ProfileDeleteAccount.vue";
import TransitionExpand from "@shared/components/utils/TransitionExpand.vue";
import { mapState } from "vuex";
import { iosview } from "@course-portal/iosview.js";
import "vue-loading-overlay/dist/css/index.css";
import NProgress from 'nprogress';

// CONSTANTS
const charLimit = 6;
const minNameChars = 2;

export default {
  name: "ProfileEdit",
  components: {
    AppContainer,
    ProfileEditPlaceholder,
    ProfileEditAvatar,
    ProfileEditAppNotifications,
    ProfileDeleteAccount,
    TransitionExpand,
  },
  data() {
    return {
      profileSaving: false,
      profileSaved: false,
      updatingSettingsFields: [],
      updatedSettingsFields: [],
      updatedAutoplay: false,
      userErrors: {
        firstName: null,
      },
      iosview: iosview
    };
  },
  computed: {
    ...mapState("settings", {
      settings: state => state.settings,
    }),
    ...mapState("user", {
      user: state => state.user,
      deleteAccount: state => state.deleteAccount,
    }),
    noUserErrors() {
      return Object.keys(this.userErrors).every((k) => !this.userErrors[k]);
    },
  },
  methods: {
    handleRestorePurchases() {
      // Dispatch the action to set isLoading to true
      this.$store.dispatch("pwa/setLoading", true);

      // Then call iosview.restorePurchases
      // Assuming iosview.restorePurchases is accessible and correctly bound
      this.iosview.restorePurchases();
    },
    saveProfile() {
      const formData = new FormData();

      formData.append("first_name", this.user.first_name);
      formData.append("last_name", this.user.last_name);

      this.validateFirstName();

      if (!this.noUserErrors) {
        return;
      }

      this.trackEvent('button_clicked', { button: 'edit_profile_view_update_profile_data' });

      if (!this.profileSaving && !this.profileSaved) {
        this.profileSaving = true;
        this.$store
          .dispatch("user/updateUser", formData)
          .then(() => {
            setTimeout(() => {
              this.profileSaved = true;
              this.profileSaving = false;
              setTimeout(() => {
                this.profileSaved = false;
              }, 8000);
            }, 1000);
          })
          .catch(() => {
            this.profileSaving = false;
          });
      }
    },
    validateFirstName() {
      if (!this.user.first_name || !this.user.first_name.length) {
        this.userErrors.firstName = "Please enter your first name";
      } else {
        delete this.userErrors.firstName;
      }
    },
    updateSettings(event, field) {
      this.updatingSettingsFields.push(field);

      this.trackEvent('button_clicked', { button: 'edit_profile_view_update_profile_data' });

      this.$store
        .dispatch("settings/updateSettings", {
          key: field,
          choice: event.target.checked,
        })
        .then(() => {
          this.updatingSettingsFields = this.updatingSettingsFields.filter(
            (item) => item !== field
          );
          this.updatedSettingsFields.push(field);

          setTimeout(() => {
            this.updatedSettingsFields = this.updatedSettingsFields.filter(
              (item) => item !== field
            );
          }, 2000);
        })
        .catch(() => {
          this.updatingSettingsFields = this.updatingSettingsFields.filter(
            (item) => item !== field
          );
          this.settings[field] = !this.settings[field];
        });
    },
    updateAppSettings (payload) {
      this.updateSettings(payload.event, payload.field)
    },
    isUpdatedField(field) {
      return this.updatedSettingsFields.find((item) => item === field);
    },
    isUpdatingField(field) {
      return this.updatingSettingsFields.find((item) => item === field);
    },
    openContactForm() {
      this.trackEvent('button_clicked', { button: 'settings_view_contact_support' });

      this.$store.dispatch("layout/toggleMenu", true);
      this.$store.dispatch("layout/setMenuComponent", {
        component: "AppMenuContact",
      });
    },
    toggleDeleteAccount () {
      this.$store.dispatch('user/toggleDeleteAccount', true)
    },
  },
  mounted() {
    this.$store.dispatch("layout/setHeaderTitle", "Profile")
      .finally(() => {
        NProgress.done();
      });
  },
  beforeUnmount() {
    this.$store.dispatch("layout/setHeaderTitle", this.$t("profile.profile"));
  },
};
</script>

<style lang="scss">
.profile-edit {
  padding: 20px 0 80px 0;

  @include tablet-portrait {
    padding: 50px 0 100px 0;
  }

  &__block {
    max-width: 400px;
    margin: 0 auto 40px auto;

    @include tablet-portrait {
      max-width: none;
      margin: 0 0 60px 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > .button.profile-edit__button {
      display: flex;
      justify-content: center;
      min-width: 108px;
      margin: 20px 0 0 0;
      transition: none;

      &--saved {
        position: relative;
        color: var(--white);
        background: var(--green);
        pointer-events: none;
      }
    }
  }
  &__button-saving {
    height: 20px;
    width: 20px;
    margin: 7px 0;
    border: 2px solid transparent;
    border-top-color: var(--black);
    border-radius: 50%;
    pointer-events: none;
    animation: loading 0.6s infinite linear;
  }
  &__block-title {
    align-items: center;
    justify-content: space-between;
    font-size: $header-sm;
    font-weight: $semiBold;
    margin: 0 0 25px 0;

    &--main {
      display: none;

      @include tablet-portrait {
        display: flex;
      }
    }
  }
  &__close {
    font-size: 32px;
    line-height: 23px;
    padding: 0 0 5px 0;
  }
  &__close-mobile {
    display: flex;
    align-items: center;
    font-size: $header-sm;
    font-weight: $semiBold;
    margin: 0 0 20px 0;

    @include tablet-portrait {
      display: none;
    }
  }
  &__close-mobile-icon {
    margin: 0 10px 0 0;
  }

  &__fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__field {
    position: relative;
    width: 100%;
    margin: 0 0 8px 0;

    &:last-child {
      margin: 0;

      .profile-edit__field-nest {
        border-bottom-color: transparent;
        &--error {
          border-bottom-color: var(--red);
        }
      }
    }

    &--half {
      @include tablet-portrait {
        width: calc(50% - 4px);
      }
    }
  }

  &__field-nest {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 61px;
    border: 1px solid transparent;
    border-radius: 5px;
    background: var(--gray-7);

    &:last-child {
      height: 60px;
    }

    @include tablet-portrait {
      min-height: 60px;
    }

    &--error {
      border-color: var(--red);
    }
  }

  &__toggler-nest {
    display: flex;
    align-items: center;
    justify-self: flex-end;
    position: relative;
    padding: 0 17px 0 10px;
    margin-left: 20px;

    @include tablet-portrait {
      padding: 0 20px 0 10px;
    }

    &--saving {
      pointer-events: none;
    }
  }
  &__toggler-saved,
  &__toggler-saving {
    position: absolute;
    top: 50%;
    right: 100%;
    font-size: $text-sm;
    color: var(--gray-3);
    transform: translateY(-50%);
  }
  &__toggler-saved {
    color: var(--gray-1);
  }

  &__field-label {
    padding: 20px 17px;

    @include tablet-portrait {
      padding: 20px 20px;
    }
  }

  &__input {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    color: var(--gray-1);
    padding: 15px 17px 0 17px;
    border: none;
    outline: none;
    background: none;

    @include tablet-portrait {
      padding: 15px 20px 0 20px;
    }

    &:focus ~ .profile-edit__placeholder,
    &:not(:placeholder-shown) ~ .profile-edit__placeholder {
      font-size: 12px;
      transform: translateY(-140%);
    }

    &--required {
      &:focus ~ .profile-edit__placeholder,
      &:not(:focus):valid ~ .profile-edit__placeholder {
        font-size: 12px;
        transform: translateY(-140%);
        &--error {
          color: var(--red);
        }
      }
    }

    &[disabled] {
      color: var(--gray-3);
    }
  }

  &__field-icon-nest {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 20px;
    color: var(--gray-3);
    transform: translateY(-50%);
    cursor: pointer;
  }

  &__field-icon {
    &--slash {
      margin-right: -1px;
    }
  }

  &__field-note {
    font-size: $text-sm;
    color: var(--gray-3);
    margin: 10px 0 0 2px;
  }
  &__field-note-link {
    color: var(--white);

    &:hover {
      text-decoration: underline;
    }
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    left: 17px;
    color: var(--gray-3);
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    cursor: text;

    @include tablet-portrait {
      left: 20px;
    }

    &--error {
      color: var(--red);
    }
  }

  &__button.button {
    font-size: $text-sm;
    line-height: 34px;
  }

  &__errors {
    font-size: $text-lg;
    text-align: left;
    padding: 0 0 0 2px;
    margin: 0 0 20px 0;

    @include tablet-portrait {
      padding: 0;
    }
  }

  &__errors-msg {
    color: var(--red);
    margin: 12px 0;
  }

  &__errors-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      font-size: $text-md;
      color: var(--gray-3);
      padding: 0 0 0 20px;
      margin: 0 0 5px 0;

      &:last-child {
        margin: 0;
      }

      &:before {
        content: "";
        position: absolute;
        left: 4px;
        top: 7px;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background: var(--red);
      }
    }
  }

  &__footer {
    font-size: $text-sm;
    text-align: center;
    color: var(--gray-4);
  }

  &__logo {
    margin: 0 auto 16px auto;

    img {
      display: block;
      margin: 0 auto 8px auto;
    }
  }

  &__delete-account {
    color: var(--gray-3);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease-in-out;
  }
  .fade-enter-from,
  .fade-leave-active {
    opacity: 0;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: transform .4s ease-in-out;

    @include desktop-sm {
      transition: opacity .4s ease-in-out;

      :deep(.delete-account__nest) {
        transition: opacity .4s ease-in-out, transform .4s ease-in-out;
      }
    }
  }
  .slide-enter-from,
  .slide-leave-active {
    transform: translateY(100%);

    @include desktop-sm {
      opacity: 0;
      transform: none;

      :deep(.delete-account__nest) {
        opacity: 0;
        transform: scale(0.8);
      }
    }
  }

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
