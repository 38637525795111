import { mapState } from 'vuex'
import fscreen from '@shared/base/fscreen'
import appLibrary from '@shared/base/appLibrary'
import i18n from '@shared/i18n'
import NProgress from 'nprogress';

export default {
  mixins: [appLibrary],
  data() {
    return {
      minProgress: 10,
      progressUpdatedAt: null,
      progressRequestInProgress: false,
      playing: false,
      floating: false,
      langSet: false
    }
  },
  computed: {
    ...mapState('lesson', {
      lesson: state => state.lesson,
      annotationActive: state => state.annotationActive,
      ignoreProgress: state => state.ignoreProgress,
      previousTimestamp: state => state.previousTimestamp,
      lockedContentOrigin: state => state.lockedContentOrigin
    }),
    ...mapState('settings', {
      settings: state => state.settings,
      guestSettings: state => state.guestSettings
    }),
    language() {
      return {
        es: 'input[value="Spanish"], input[value="español"]',
        de: 'input[value="German"], input[value="Deutsch"]',
        fr: 'input[value="French"], input[value="français"]',
        pt: 'input[value="Portuguese"], input[value="Português"]'
      }
    },
    options() {
      return {
        playerColor: '171719',
        wmode: 'transparent',
        autoPlay: true,
        silentAutoPlay: false,
        resumable: false,
        endVideoBehavior: 'reset',
        controlsVisibleOnLoad: false,
        copyLinkAndThumbnailEnabled: false,
        fullscreenButton: true,
        fullscreenOnRotateToLandscape: true,
        playbar: true,
        playButton: true,
        smallPlayButton: true,
        volumeControl: true,
        plugin: {
          'captions-v1': {
            onByDefault: this.guest ? false : this.$store.state.settings.settings.captions ?? false,
            language: (this.guest ? false : this.$store.state.settings.settings.captions ?? false) ? 'eng' : ''
          }
        }
      }
    },
    isTheaterMode() {
      return this.guest ? this.guestSettings.theater_mode : this.settings.theater_mode
    },
    trackProgress() {
      return this.lesson && this.lesson.is_video && !this.lesson.is_watched && !this.progressRequestInProgess
    },
    isPreviousLesson() {
      return parseInt(this.$route.params.id) === this.lockedContentOrigin
    },
    isPreviousTimestamp() {
      return this.lesson.is_watched && Number.isInteger(this.previousTimestamp)
    },
    timestamp() {
      return this.isPreviousLesson && this.isPreviousTimestamp ? this.previousTimestamp : this.$route.params.timestamp
    },
    startTime() {
      if (this.ignoreProgress) {
        return 0
      }
      return this.timestamp || this.timestamp === "0" ? this.timestamp : this.lesson.is_watched ? 0 : this.lesson.progress - this.minProgress
    },
    nextProgressUpdateAt() {
      return (this.progressUpdatedAt ?? this.startTime) + this.minProgress
    }
  },
  methods: {
    wistiaConfig() {
      const vm = this
      window._wq = window._wq || []

      _wq.push({
        id: this.lesson.video_id,
        options: this.options,
        onReady: (video) => {
          this.generateWistiaUI()
          this.bindPlayerEvents(video)
          this.floatingVideo(video)
          this.ambilightEffect(video)
          this.setGlobalVideoQuality(video)
          this.$store.dispatch('lesson/setShowUpcoming', false)
          this.$store.dispatch('lesson/toggleNextVideoLoading', false)
          NProgress.done();
          video.bind("play", function() {
            vm.setDefaultLanguage()
          })
        },
        onEmbedded: async (video) => {
          let settingsButtonEl = document.querySelector('[data-handle="settingsButton"] button');
          // keep querying and add timeout till settings button is loaded
          while (!settingsButtonEl) {
            await new Promise((resolve) => setTimeout(() => {
              settingsButtonEl = document.querySelector('[data-handle="settingsButton"] button');
              resolve()
            }, 500));
          }
          const bindPlaybackRateButtonClick = () => {
            const el = document.querySelector('[data-handle="playbackRate"]');
            if (el && !el.classList.contains('w-patched')) {
              el.addEventListener('click', () => {
                if (vm.fireTrackerEvent) {
                  vm.fireTrackerEvent('button_clicked', 'video_player_view_playback_speed')
                }
              })
              el.classList.add('w-patched')
              return
            }
          };
          const bindQualityButtonClick = () => {
            const el = document.querySelector('[data-handle="quality"]');
            if (el && !el.classList.contains('w-patched')) {
              el.addEventListener('click', () => {
                if (vm.fireTrackerEvent) {
                  vm.fireTrackerEvent('button_clicked', 'video_player_view_playback_quality')
                }
              })
              el.classList.add('w-patched')
              return
            }
          };

          settingsButtonEl.addEventListener('click', async () => {
            if (vm.fireTrackerEvent) {
              vm.fireTrackerEvent('button_clicked', 'video_player_view_more')
            }
            bindPlaybackRateButtonClick();
            bindQualityButtonClick();

            await new Promise(() => setTimeout(() => {

              const qualityInputs = document.querySelectorAll('input[name="Quality"]');
              if (qualityInputs.length) {
                qualityInputs.forEach(el => {
                  el.addEventListener("change", () => {
                    const activeQualityElement = document.querySelector('input[name="Quality"]:checked');

                    const quality = activeQualityElement.value === "Auto" ? "auto" : activeQualityElement.value.slice(0, -1);
                    this.$store.dispatch('settings/updateSettings', { key: 'video_quality', choice: quality });
                  });
                })
              }
            }, 500))
          })
        }
      })
    },
    setDefaultLanguage()  {
      if (this.currentLanguage === 'en' || this.langSet) {
        this.langSet = true
        return
      }

      const button = document.querySelector('button[aria-label="Show audio description menu"]')

      if (!button)  {
        this.langSet = true
        return
      }

      button.click()

      setTimeout(() => {
        const langButton = document.querySelector(this.language[this.currentLanguage])

        if (!langButton)  {
          button.click()
          setTimeout(() => {
            this.langSet = true
          }, 600)
          return
        }

        langButton.click()

        setTimeout(() => {
          button.click()

          setTimeout(() => {
            this.langSet = true
          }, 600)
        }, 600)
      }, 600)
    },
    handleProgress(seconds) {
      // Keep track of the user progress only when the lesson is not marked as watched.
      if (!this.trackProgress) {
        return
      }

      // Make sure we update the progress at each 10s interval.
      if (seconds < this.nextProgressUpdateAt) {
        return
      }

      this.progressRequestInProgress = true

      this.$store.dispatch('lesson/setProgress', {
        lessonProgress: seconds
      }).finally(() => {
        this.progressRequestInProgress = false
        this.progressUpdatedAt = seconds
      })
    },
    handleAnnotations(seconds) {
      if (!this.lesson || !this.lesson.annotations) {
        return
      }

      let isShowingLink = false

      this.lesson.annotations.forEach(annotation => {
        if (annotation.type === 'related_video') return

        if (seconds >= annotation.start && seconds < annotation.end) {
          if (!isShowingLink) {
            this.$store.dispatch('lesson/toggleAnnotation', annotation.id)
            isShowingLink = true
          }
        } else {
          if (annotation.id === this.annotationActive) {
            this.$store.dispatch('lesson/toggleAnnotation', null)
            isShowingLink = false
          }
        }
      })
    },
    bindPlayerEvents(video) {
      if (!this.guest) {
        const queryTimestamp = this.$route.query?.t
        video.time((queryTimestamp ? Math.min(this.lesson.duration - this.minProgress, queryTimestamp) : 0) || this.startTime)
        video.bind('play', () => {
          if (this.isPreviousLesson) {
            this.$store.dispatch('lesson/setPreviousTimestamp', null)
          }

          this.$store.dispatch('lesson/setShowUpcoming', false)
          this.playing = true
          if (this.fireTrackerEvent) {
            this.fireTrackerEvent('button_clicked', 'video_player_view_play_pause')
          }
        })

        video.bind('pause', () => {
          this.playing = false
          if (this.fireTrackerEvent) {
            this.fireTrackerEvent('button_clicked', 'video_player_view_play_pause')
          }
        })
        const vm = this;

        video.plugin('captions').then(function (captions) {
          captions.fetched.then(data => {
            const captionsButton = video.controls.captionsButton.buttonElement
            if (captionsButton) {
              captionsButton.addEventListener('click', () => {
                if (vm.fireTrackerEvent) {
                  vm.fireTrackerEvent('button_clicked', 'video_player_settings_captions_section', { is_on: captionsButton.ariaExpanded })
                }
              })
            }
          })
        })
        let nextThreshold = video.time() + this.minProgress;
        video.bind('secondchange', (seconds) => {
          if (video.state() !== 'playing') return;
          if (seconds > nextThreshold) {
            nextThreshold += this.minProgress;
            this.handleProgress(seconds)
          }
          if (seconds > this.minProgress) {
            this.handleAnnotations(seconds)
          }
        })
      }

      video.bind('end', () => {
        // Open "next lesson" popup
        if (this.lesson.next_lesson_id) {
          this.$store.dispatch('lesson/setShowUpcoming', true)
        }
        // Close fullscreen mode on mobile devices
        if (!fscreen.fullscreenEnabled) {
          document.querySelector('video').webkitExitFullscreen()
        }
      })

      video.bind('cancelfullscreen', () => {
        if (this.fireTrackerEvent) {
          this.fireTrackerEvent('button_clicked', 'video_player_view_closed_fullscreen')
        }
      })

      if (this.isPhone && this.fireTrackerEvent) {
        video.controls.skipAhead.onClick = () => {
          video.time(video.time() + 15);
          if (this.fireTrackerEvent) {
            this.fireTrackerEvent('button_clicked', 'video_player_view_go_forward')
          }
        }
        video.controls.skipBack.onClick = () => {
          video.time(video.time() - 15);
          if (this.fireTrackerEvent) {
            this.fireTrackerEvent('button_clicked', 'video_player_view_go_backwards')
          }
        }
      }

      video.bind('playbackratechange', (updated_playback_speed) => {
        if (this.fireTrackerEvent) {
          this.fireTrackerEvent('video_settings_playback_speed_changed', null, { updated_playback_speed })
        }
      })

      video.bind('seek', () => {
        if (this.fireTrackerEvent) {
          this.fireTrackerEvent('video_player_view_drag_seek_bar')
        }
      })

      if (!this.isPhone) {
        video.setControlEnabled('chapters', false)
      }
      if (this.ignoreProgress) {
        this.$store.dispatch('lesson/setIgnoreProgress', false)
      }
      // Hide Wistia generic fullscreen button
      if (fscreen.fullscreenEnabled || this.isPhone) {
        video.setControlEnabled('fullscreenControl', false)
      }
    },
    floatingVideo() {
      if (this.isPhone) {
        return
      }

      const videoNest = document.querySelector('.video')
      const videoElem = document.querySelector('.w-video-wrapper').parentNode
      const videoHeight = videoElem.clientHeight
      const videoBottom = videoHeight + videoElem.getBoundingClientRect().top - this.headerHeight

      window.addEventListener('scroll', () => {
        if (window.scrollY > videoBottom) {
          videoNest.style.height = `${videoHeight}px`
          if (this.fireTrackerEvent && !this.floating) {
            this.fireTrackerEvent('button_clicked', 'video_player_view_picture_in_picture')
          }
          this.floating = true

        } else {
          videoNest.style.height = 'auto'
          if (this.fireTrackerEvent && this.floating) {
            this.fireTrackerEvent('button_clicked', 'video_player_view_picture_in_picture')
          }
          this.floating = false
        }
      })
    },
    ambilightEffect(video) {
      const FRAMERATE = 30
      const videoNest = document.querySelector('.video')
      const posterElem = '.wistia_responsive_wrapper [data-handle="thumbnail"] img'
      const videoElem = document.querySelector('.wistia_responsive_wrapper video')
      const canvas = this.$refs['ambilight']
      // this throws an error when the page is loading
      if (!canvas) return
      const context = canvas.getContext('2d')
      let width
      let height
      let intervalId

      const drawAmbilight = () => {
        width = parseInt((videoNest.clientHeight * video.aspect()).toFixed(0))
        height = parseInt(videoNest.clientHeight.toFixed(0))

        canvas.width = width
        canvas.height = height

        repaintAmbilight()
      }
      const repaintAmbilight = () => {
        video.state() === 'beforeplay'
          ? this.waitForElement(posterElem).then(elem => context.drawImage(elem, 0, 0, width, height))
          : context.drawImage(videoElem, 0, 0, width, height)
      }
      const startAmbilightRepaint = () => {
        intervalId = window.setInterval(repaintAmbilight, 1000 / FRAMERATE)
      }
      const stopAmbilightRepaint = () => {
        clearInterval(intervalId)
      }

      this.afterOrientationChange(drawAmbilight)
      window.addEventListener('resize', drawAmbilight)
      if (!videoElem)
        return
      videoElem.addEventListener('play', startAmbilightRepaint)
      videoElem.addEventListener('pause', stopAmbilightRepaint)
      videoElem.addEventListener('ended', stopAmbilightRepaint)
      videoElem.addEventListener('seeked', repaintAmbilight)
      videoElem.addEventListener('load', repaintAmbilight)

      drawAmbilight()
    },
    createDesktopSkipVideoButton() {
      const vm = this

      class SkipButton {
        mountButton(buttonRoot) {
          buttonRoot.setAttribute('title', i18n.global.t('wistia.goToNextVideo'))
          buttonRoot.innerHTML = `<img src="${vm.vaporAsset('images/icons/next-video.svg')}" alt="next" />`
        }
        onClickButton() {
          if (vm.fireTrackerEvent) {
            vm.fireTrackerEvent('button_clicked', 'video_player_view_play_next')
          }
          vm.$router.push({ name: 'lesson', params: { id: vm.$store.state.lesson.lesson.next_lesson_id, ...(vm.isFullscreen() && { autoplay: true }) } })
        }
      }

      // Show button only if next lesson is available
      SkipButton.shouldMount = () => {
        if (vm.$store.state.lesson.lesson) {
          return vm.$store.state.lesson.lesson.next_lesson_id
        }
      }

      SkipButton.handle = 'skipVideo'
      SkipButton.type = 'control-bar-right'
      SkipButton.sortValue = 1

      Wistia.defineControl(SkipButton)
    },
    createMobileSkipVideoButton() {
      const vm = this

      class SkipButton {
        mount(rootElem) {
          this.button = document.createElement('div')
          this.button.setAttribute('class', 'w-next-video')
          this.button.innerHTML = `<img src="${vm.vaporAsset('images/icons/next-video.svg')}" alt="skip">`
          this.button.style.opacity = 0
          this.button.style.transition = 'opacity 200ms ease'
          rootElem.addEventListener('click', this.onClick)
          rootElem.appendChild(this.button)
        }
        onClick() {
          if (vm.fireTrackerEvent) {
            vm.fireTrackerEvent('button_clicked', 'video_player_view_play_next')
          }
          vm.$router.push({ name: 'lesson', params: { id: vm.$store.state.lesson.lesson.next_lesson_id } })
        }
        onControlPropsUpdated() {
          this.button.style.opacity = this.props.controlsAreVisible ? 1 : 0
        }
      }

      // Show button only if next lesson is available
      SkipButton.shouldMount = () => {
        if (vm.$store.state.lesson.lesson) {
          return vm.$store.state.lesson.lesson.next_lesson_id
        }
      }

      SkipButton.handle = 'skipVideo'
      SkipButton.type = 'above-control-bar'
      SkipButton.sortValue = 50

      Wistia.defineControl(SkipButton)
    },
    createDesktopFullscreenButton() {
      const vm = this
      class DesktopFullscreenButton {
        mountButton(buttonRoot) {
          buttonRoot.setAttribute('title', i18n.global.t('wistia.fullscreen'))
          buttonRoot.innerHTML = `<img src="${vm.vaporAsset('images/icons/fullscreen.svg')}" alt="fs">`
        }
        onClickButton() {
          vm.toggleFullscreen()
        }
        onControlPropsUpdated() {
          vm.$store.dispatch('layout/toggleVideoControlsVisible', this.props.controlsAreVisible)
        }
      }

      DesktopFullscreenButton.handle = i18n.global.t('wistia.fullscreen')
      DesktopFullscreenButton.type = 'control-bar-right'
      DesktopFullscreenButton.sortValue = 1000

      Wistia.defineControl(DesktopFullscreenButton)
    },
    createMobileFullscreenButton() {
      const vm = this
      class MobileFullscreenButton {
        constructor(video) {
          this.video = video
        }
        mount(rootElem) {
          this.button = document.createElement('div')
          this.button.setAttribute('class', 'w-fullscreen')
          this.button.innerHTML = `<img src="${vm.vaporAsset('images/icons/fullscreen.svg')}" alt="fs">`
          this.button.style.opacity = 0
          this.button.style.transition = 'opacity 200ms ease'
          rootElem.addEventListener('click', () => this.onClick(this.video))
          rootElem.appendChild(this.button)
        }
        onClick(video) {
          video.inFullscreen() ? video.cancelFullscreen() : video.requestFullscreen()
        }
        onControlPropsUpdated() {
          this.button.style.opacity = this.props.controlsAreVisible ? 1 : 0
        }
      }

      MobileFullscreenButton.handle = 'fullscreenVideo'
      MobileFullscreenButton.type = 'above-control-bar'
      MobileFullscreenButton.sortValue = 50

      Wistia.defineControl(MobileFullscreenButton)
    },
    createTheaterModeButton() {
      const vm = this

      class TheaterModeButton {
        mountButton(buttonRoot) {
          buttonRoot.setAttribute('title', i18n.global.t('wistia.theaterMode'))
          buttonRoot.innerHTML = `
            <img src="${vm.vaporAsset('images/icons/theater_mode_on.svg')}" alt="fs" class="video__icon-theater--on" style="display: ${vm.isTheaterMode ? 'inline-block' : 'none'}">
            <img src="${vm.vaporAsset('images/icons/theater_mode_off.svg')}" alt="fs" class="video__icon-theater--off" style="display: ${vm.isTheaterMode ? 'none' : 'inline-block'}">
          `
        }
        onClickButton() {
          const theaterIconOn = document.querySelector('.video__icon-theater--on')
          const theaterIconOff = document.querySelector('.video__icon-theater--off')

          vm.guest
            ? vm.$store.dispatch('settings/updateGuestSettings', { key: 'theater_mode', choice: !vm.isTheaterMode })
            : vm.$store.dispatch('settings/updateSettings', { key: 'theater_mode', choice: !vm.isTheaterMode })

          if (vm.isTheaterMode) {
            theaterIconOn.style.display = 'inline-block'
            theaterIconOff.style.display = 'none'
          } else {
            theaterIconOn.style.display = 'none'
            theaterIconOff.style.display = 'inline-block'
          }
        }
      }

      TheaterModeButton.handle = 'theater'
      TheaterModeButton.type = 'control-bar-right'
      TheaterModeButton.sortValue = 900

      Wistia.defineControl(TheaterModeButton)
    },
    createMobileControlsBg() {
      const vm = this

      class ControlsBackground {
        mount(rootElem) {
          this.bg = document.createElement('div')
          this.bg.setAttribute('class', 'w-controls-bg')
          this.bg.style.opacity = 0
          this.bg.style.transition = 'opacity 200ms ease'
          rootElem.appendChild(this.bg)
        }
        onControlPropsUpdated() {
          const UIContainer = document.querySelector('.w-ui-container')
          // Prevents accidental controls interaction
          // when they are hidden
          if (UIContainer) {
            this.props.controlsAreVisible
              ? UIContainer.classList.remove('w-ui-container--disabled')
              : UIContainer.classList.add('w-ui-container--disabled')
          }
          // Show controls background when they are visible
          this.bg.style.opacity = this.props.controlsAreVisible ? 1 : 0
          vm.$store.dispatch('layout/toggleVideoControlsVisible', this.props.controlsAreVisible)
        }
      }

      ControlsBackground.handle = 'constrolsBg'
      ControlsBackground.type = 'above-control-bar'
      ControlsBackground.sortValue = -10

      Wistia.defineControl(ControlsBackground)
    },
    toggleFullscreen() {
      if (!this.isFullscreen()) {
        if (document.querySelector('.wistia_responsive_wrapper').requestFullscreen) {
          document.querySelector('.wistia_responsive_wrapper').requestFullscreen()
        } else if (document.querySelector('.wistia_responsive_wrapper').mozRequestFullScreen) {
          document.querySelector('.wistia_responsive_wrapper').mozRequestFullScreen()
        } else if (document.querySelector('.wistia_responsive_wrapper').webkitRequestFullscreen) {
          document.querySelector('.wistia_responsive_wrapper').webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
        } else if (document.querySelector('.wistia_responsive_wrapper').msRequestFullscreen) {
          document.querySelector('.wistia_responsive_wrapper').msRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
        }
      } else {
        this.closeFullscreen()
      }
    },
    generateWistiaUI() {
      if (this.isMobile) {
        this.createMobileControlsBg()
        if (!this.isIPad) {
          this.createMobileFullscreenButton()
          this.createMobileSkipVideoButton()
        } else {
          this.createDesktopSkipVideoButton()
          if (fscreen.fullscreenEnabled) {
            this.createDesktopFullscreenButton()
          }
        }
      } else {
        this.createDesktopSkipVideoButton()
        this.createDesktopFullscreenButton()
        this.createTheaterModeButton()
      }

    },
    setGlobalVideoQuality(video) {
      if (this.settings?.video_quality) {
        video.videoQuality(this.settings.video_quality);
      }
    }
  }
}
