<template>
  <div class="non-video">
    <div class="non-video__nest">
      <div class="non-video__inner">
        <img :src="lesson.thumbnail" alt="" class="non-video__img">
        <a v-if="!lesson.course.locked" :href="lesson.cta_url" target="_blank" class="non-video__btn" @click="fireTrackerEvent">
          {{ preventWidows(lesson.cta_text) }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NProgress from 'nprogress';

export default {
  name: 'LessonNonVideo',
  computed: {
    ...mapState('lesson', {
      lesson: state => state.lesson,
      module: state => state.module
    }),
    ...mapState('course', {
      course: state => state.course
    }),
  },
  mounted () {
    NProgress.done();
  },
  methods: {
    fireTrackerEvent () {
      this.trackEvent('button_clicked', {
        button: 'e_book_lesson_view_download',
        course_id: this.course.id,
        course_slug: this.course.product_slug,
        course_name: this.course.title,
        module_id: this.module.id,
        module_name: this.module.title,
        lesson_id: this.lesson.id,
        lesson_name: this.lesson.title,
      })
    }
  }
}
</script>

<style lang="scss">
.non-video {
  position: relative;
  background-color: var(--black);
  background-size: cover;
  z-index: 100;

  @include tablet-landscape {
    border-radius: 3px;
    overflow: hidden;
  }
  @include ipad-pro-129(portrait) {
    border-radius: 0;
  }

  &__nest {
    position: relative;
    padding-bottom: 56.25%;
    background: var(--gray-7);

    @media only screen and (max-device-width: 428px) and (orientation: landscape) {
      padding-bottom: calc(100vh - #{$app-header-height});
    }
  }

  &__inner {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);

    img {
      display: block;
      max-width: 60%;
      margin: 0 auto;
    }
  }

  &__btn {
    display: table;
    font-size: $text-xs;
    line-height: 110%;
    font-weight: $semiBold;
    text-align: center;
    color: var(--gray-1);
    padding: 12px 20px;
    margin: 15px auto 0 auto;
    border-radius: 6px;
    background: linear-gradient(180deg, var(--red-gradient));
    background-position: 50% 50%;
    background-size: 100% 150%;
    transition: background-position .2s ease-in-out;

    &:hover {
      background-position: 50% 0%;
    }
    @include phone-portrait {
      font-size: $text-sm;
    }
    @include tablet-landscape (landscape) {
      font-size: 20px;
      line-height: 50px;
      padding: 0 40px;
      margin: 30px auto 0 auto;
    }
  }
}
</style>
